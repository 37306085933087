import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, App, Select, Input, DatePicker } from 'antd';
import { consoleLog, langError } from 'tools';
import { useFetchRiskifyQuotaType, useFetchRiskifySetMembershipCredit } from "hooks";

const dateFormat = 'YYYY-MM-DD';

export const ModalSettingsCredit = ({ open, currentKey, onCancel, onHandleUpdateInfo}) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm();
    const [loadingSave, setLoadingSave] = useState(false);  // save按钮的loading效果
    const [loadingOption, setLoadingOption] = useState(false);  // save按钮的loading效果
    const [optionList, setOptionList] = useState([]);  // save按钮的loading效果
     const [dataStr, setDataStr] = useState("");  // 设置过期时间字符串

    const [fetchSave] = useFetchRiskifySetMembershipCredit();
    const [fetchCreditType] = useFetchRiskifyQuotaType();

    useEffect(()=>{
        setLoadingOption(true);
        fetchCreditType({}, {
            success: (data) => {
                setLoadingOption(false);
                setOptionList(data);
            },
            error: (error) => {
                setLoadingOption(false);
                notification.error({'message':langError(error.code)});
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const onChange = (date, dateString) => {
        setDataStr(dateString);
    };


    const handleSave = () =>{
        form
        .validateFields()
        .then(values => {
            setLoadingSave(true);
            fetchSave({
                expires: dataStr,
                type: values.type,
                user: currentKey,
                value: values.value
            }, {
                success: (data) => {
                    setLoadingSave(false);
                    onCancel();
                    if(onHandleUpdateInfo){
                        onHandleUpdateInfo();
                    }
                    notification.success({"message":"保存成功"});
                },
                error: (error) => {
                    setLoadingSave(false);
                    notification.error({'message':langError(error.code)});
                },
            });
        })
        .catch(info => {
            consoleLog('Validate Failed:', info);
        });
    }
    return (
        <Modal
            destroyOnClose
            open={open}
            title={"设置额度"}
            onCancel={onCancel}
            width={500}
            footer={
                [
                    <Button key="cancel" onClick={onCancel}>取消</Button>,
                    <Button key="save" type='primary' loading={loadingSave} onClick={handleSave}>确定</Button>
                ]
            }
        >
            <Form
                form={form}
                layout="vertical"
                autoComplete='off'
            >
                <Form.Item
                    name="type"
                    label={"设置额度类型"}
                    style={{marginBottom:10}}
                    rules={[{required:true, message:"请选择额度类型"}]}
                >
                   <Select loading={loadingOption} placeholder="请选择额度类型">
                        {
                            optionList.length>0?
                                optionList.map(item =>(
                                    <Select.Option key={item.key} value={item.key}>{item.value}</Select.Option>
                                )):null
                        }
                   </Select>
                </Form.Item>
                <Form.Item
                    name="value"
                    label={"额度"}
                    style={{marginBottom:10}}
                    rules={[{required:true, message:"请输入额度"}]}
                >
                   <Input placeholder="请输入额度" />
                </Form.Item>
                <Form.Item
                    name="expires"
                    label={"过期时间"}
                    rules={[{required:true, message:"请选择过期时间"}]}
                >
                   <DatePicker onChange={onChange} format={dateFormat} style={{width:"100%"}} />
                </Form.Item>
                
            </Form>
        </Modal>
    );
};

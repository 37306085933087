import React, { useState } from "react";
import { App, Card, Descriptions, Row, Spin, Dropdown, Button, notification, Tag } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { bemLevelMap, getDateCN, langError } from "tools";
import { useFetchRiskifyUserSuspend, useFetchRiskifyUserUnSuspend } from "hooks";
import { ModalSettings } from "./ModalSettings";
import { ModalSettingsCredit } from "./ModalSettingsCredit";

export const CardBasicInfo = ({ loading, userInfo, onHandleUpdateInfo }) => {
	const { key, status, name, credits, credits_all, membership_type, membership_expired, membership_category } = userInfo || {};
	const { modal } = App.useApp();

	const [openSettingModal, setOpenSettingModal] = useState(false);
	const [openSettingCreditModal, setOpenSettingCreditModal] = useState(false);

	const [fetchSuspend] = useFetchRiskifyUserSuspend();
	const [fetchUnSuspend] = useFetchRiskifyUserUnSuspend();

	const typeMap = {
		1: <Tag color="green">正常</Tag>,
		9: <Tag color="red">禁用</Tag>,
	};

	const dataSource = [
		{ key: "Key", value: key },
		{ key: "状态", value: typeMap[status] },
		{ key: "姓名", value: name },
		{ key: "credits", value: credits ? credits : 0 },
		{ key: "credits_all", value: credits_all ? credits_all : 0 },
		{ key: "membership_type", value: bemLevelMap[membership_type] },
		{ key: "membership_category", value: membership_category },
		{ key: "membership_expired", value: membership_expired ? getDateCN(membership_expired) : "Lifetime" },
	];

	const formatData = dataSource.map((item) => {
		return {
			...item,
			value: item.value || "",
		};
	});

	const itemsDropdown = [
		{
			label: status === 1 ? "禁用" : "启用",
			key: 'edit',
		},
		{
			label: "设为会员",
			key: 'set',
		},
		{
			label: "设置额度",
			key: 'set_credit',
		},
	];

	return (
		<>
			<Row align="middle" justify="space-between" style={{ marginBottom: "25px" }}>
				<div>
					<h2>
						用户详情 - {!name ? <Spin /> : name}
					</h2>
				</div>
				<div>
					<Dropdown
						menu={{
							items: itemsDropdown,
							onClick: (item) => {
								if (item.key === "edit") {
									modal.confirm({
										title: "设置状态",
										content: `确定${status === 1 ? "禁用" : "启用"}吗？`,
										onOk: () => {
											if (status === 1) {
												fetchSuspend({
													key: key
												}, {
													success: (data) => {
														if (onHandleUpdateInfo) {
															onHandleUpdateInfo();
														}
														notification.success({ message: "禁用成功" });
													},
													error: (error) => {
														notification.error({ message: langError(error.code) });
													},
												});
											} else {
												fetchUnSuspend({
													key: key
												}, {
													success: (data) => {
														if (onHandleUpdateInfo) {
															onHandleUpdateInfo();
														}
														notification.success({ message: "启用成功" });
													},
													error: (error) => {
														notification.error({ message: langError(error.code) });
													},
												});
											}

										}
									})
								} else if (item.key === "set") {
									setOpenSettingModal(true);
								} else if (item.key === "set_credit") {
									setOpenSettingCreditModal(true);
								}
							},
						}}
						trigger={['click']}
						placement="bottomRight"
					>
						<Button type="primary">操作 <DownOutlined /></Button>
					</Dropdown>
				</div>
			</Row>
			<Card title="基础信息" loading={loading}>
				<Descriptions
					bordered
					layout="horizontal"
					labelStyle={{ fontWeight: "bold" }}
				>
					{formatData.map((item, index) => (
						<Descriptions.Item label={item.key} key={index}>
							{item.value}
						</Descriptions.Item>
					))}
				</Descriptions>
			</Card>
			
			<ModalSettings
				open={openSettingModal}
				currentKey={key}
				onCancel={() => setOpenSettingModal(false)}
				onHandleUpdateInfo={onHandleUpdateInfo}
			/>
			<ModalSettingsCredit
				open={openSettingCreditModal}
				currentKey={key}
				onCancel={() => setOpenSettingCreditModal(false)}
				onHandleUpdateInfo={onHandleUpdateInfo}
			/>
		</>
	);
};

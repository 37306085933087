import React from "react";
import { App, Card, Table } from "antd";
import { getDateCN, creditStatusMap, langError } from "tools";
import { useFetchRiskifyUserCancelCredit } from "hooks";

const tabListTitle = [
    {
        key: 'credit_list',
        label: 'Credit List',
    }
];

export const CardListInfo = ({ loading, listData, activeKey, pageCurrent, dataTotal, pageSize, onHandleChangeTab, onHandleChangePage, onHandleUpdateInfo = null }) => {
    const { modal, notification } = App.useApp();
    const [fetchCancel] = useFetchRiskifyUserCancelCredit();

    const onTabChange = (key) => {
        if (onHandleChangeTab) {
            onHandleChangeTab(key)
        }
    };

    const oncancel = record =>{
        modal.confirm({
            title: '确认作废？',
            content: '作废后将无法恢复',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                fetchCancel({
                    key: record.user,
                    id_credit: record.id
                }, {
                    success: (data) => {
                        if (onHandleUpdateInfo) {
                            onHandleUpdateInfo();
                        }
                        notification.success({ message: "操作成功" });
                    },
                    error: (error) => {
                        notification.error({ message: langError(error.code) });
                    },
                });
            },
        })
    }

    let columns = [];
    if (activeKey === "credit_list") {
        columns = [
            {
                title: "额度",
                dataIndex: "face_value",
            },
            {
                title: "余额",
                dataIndex: "balance",
            },
            {
                title: "状态",
                dataIndex: "status",
                render: (_, record) => (
                    <span>{creditStatusMap[record.status]}</span>
                )
            },
            {
                title: "创建时间",
                dataIndex: "time_create",
                render: (_, record) => (
                    <span>{getDateCN(record.time_create)}</span>
                )
            },
            {
                title: "过期时间",
                dataIndex: "time_expired",
                render: (_, record) => (
                    <span>{getDateCN(record.time_expired)}</span>
                )
            },
            {
                title: "操作",
                dataIndex: "action",
                width:100,
                render: (_, record) => (
                    record?.status === 1 && <a onClick={() =>{oncancel(record)}}>作废</a>
                )
            },
        ];
    } 

    return (
        <Card
            loading={loading}
            style={{
                width: '100%',
            }}
            tabList={tabListTitle}
            activeTabKey={activeKey}
            onTabChange={onTabChange}
        >
            <Table
                dataSource={listData}
                columns={columns}
                scroll={{ x: "max-content", y: 600 }}
                pagination={false}
                rowKey={record => record.id || record.uid || record.key}
            />
        </Card>
    );
};

import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Layout, Space } from "antd";
import { LayoutAuthRequired, LayoutHeaderNav } from "components";
import { LayoutPageSidebar, LayoutPageContent } from "../components";
import usePageTitle from "hooks/usePageTitle";
import { CardBasicInfo } from "./components";
import { useFetchRiskifyUserDetail } from "hooks";
import { use } from "react";
import { CardListInfo } from "./components/CardListInfo";

const PageRiskifyUserDetails = () => {
    const [loading, setLoading] = useState(false);

    usePageTitle("用户详情 - Riskify插件管理后台");

    const [search] = useSearchParams();
    const id = search.get("uid");
    const filter_type = search.get("filter_type");

    const [fetchUserDetail] = useFetchRiskifyUserDetail();
    const [listData, setListData] = useState([]);
    const [pageCurrent, setPageCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [dataTotal, setDataTotal] = useState(0);
    const [userInfo, setUserInfo] = useState(null);
    const [forceFetch, setForceFetch] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        fetchUserDetail({
            key: id,
            type: filter_type ? filter_type : "credit_list",
            page: pageCurrent
        },
            {
                success: (data) => {
                    setUserInfo(data);
                    setListData(data.credits_list);
                    setLoading(false);
                },
                error: (error) => {
                    console.log(error);
                    setLoading(false);
                },
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, search, pageCurrent, forceFetch]);

    const handleChangeTab = key => {
        let url = `&filter_type=${key}`;
        navigate(`/ms/user/detail?uid=${id}${url}`)
    }

    const handleChangePage = (page) => {
        setPageCurrent(page);
        window.scroll(0, 0);
    };

    return (
        <LayoutAuthRequired>
            <Layout
                theme="light"
                style={{
                    minHeight: "100vh",
                }}
            >
                <LayoutHeaderNav />
                <Layout>
                    <LayoutPageSidebar />
                    <LayoutPageContent>
                        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                            <CardBasicInfo loading={loading} userInfo={userInfo} onHandleUpdateInfo={() => setForceFetch(forceFetch => forceFetch + 1)} />
                            <CardListInfo loading={loading} listData={listData} activeKey={filter_type ? filter_type : "credit_list"} pageCurrent={pageCurrent} dataTotal={dataTotal} pageSize={pageSize} onHandleChangeTab={handleChangeTab} onHandleChangePage={handleChangePage} onHandleUpdateInfo={() => setForceFetch(forceFetch => forceFetch + 1)} />
                        </Space>
                    </LayoutPageContent>
                </Layout>
            </Layout>
        </LayoutAuthRequired>
    );
};

export default PageRiskifyUserDetails;

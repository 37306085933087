import React, { useState } from 'react';
import { Modal, Form, Button, App, Select, DatePicker } from 'antd';
import { consoleLog, langError } from 'tools';
import { useFetchRiskifySetMembership } from "hooks";

const dateFormat = 'YYYY-MM-DD';

export const ModalSettings = ({ open, currentKey, onCancel, onHandleUpdateInfo }) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm();
    const [loadingSave, setLoadingSave] = useState(false);  // save按钮的loading效果
    const [dataStr, setDataStr] = useState("");  // 设置过期时间字符串

    const [fetchSave] = useFetchRiskifySetMembership();

    const onChange = (date, dateString) => {
        setDataStr(dateString);
    };

    const handleSave = () =>{
        form
        .validateFields()
        .then(values => {
            setLoadingSave(true);
            fetchSave({
                expires: dataStr,
                package_type: values.package_type,
                key: currentKey,
            }, {
                success: (data) => {
                    setLoadingSave(false);
                    onCancel();
                    if(onHandleUpdateInfo){
                        onHandleUpdateInfo();
                    }
                    notification.success({"message":"保存成功"});
                },
                error: (error) => {
                    setLoadingSave(false);
                    notification.error({'message':langError(error.code)});
                },
            });
        })
        .catch(info => {
            consoleLog('Validate Failed:', info);
        });
    }
    return (
        <Modal
            destroyOnClose
            open={open}
            title={"设为会员"}
            onCancel={onCancel}
            width={500}
            footer={
                [
                    <Button key="cancel" onClick={onCancel}>取消</Button>,
                    <Button key="save" type='primary' loading={loadingSave} onClick={handleSave}>确定</Button>
                ]
            }
        >
            <Form
                form={form}
                layout="vertical"
                autoComplete='off'
            >
                <Form.Item
                    name="package_type"
                    label={"类型"}
                    style={{marginBottom:10}}
                    rules={[{required:true, message:"请选择类型"}]}
                >
                   <Select placeholder="请选择类型">
                        <Select.Option key="pro" value="pro">PRO</Select.Option>
                        <Select.Option key="elite" value="elite">ELITE</Select.Option>
                   </Select>
                </Form.Item>
                <Form.Item
                    name="expires"
                    label={"过期时间"}
                    rules={[{required:true, message:"请选择过期时间"}]}
                >
                   <DatePicker onChange={onChange} format={dateFormat} style={{width:"100%"}} />
                </Form.Item>
            </Form>
        </Modal>
    );
};
